<template>
	<div class="ele-body">
    <el-card >
      <el-table
          :data="tableData"
        >
         
          <el-table-column
          
          align="center"
            label="语音类型"
            >
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>语音类型: {{ scope.row.name }}</p>
                <p>音频地址: {{ scope.row.address }}</p>
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium">{{ scope.row.name }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          
          
          
          
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="handleEdit(scope.$index, scope.row)">播放测试</el-button>
             
            </template>
          </el-table-column>
        </el-table>
      
      

    </el-card>
    <audio src="" ref="MusicPlay" hidden></audio>

	

			
	
	</div>
</template>

<script>
   export default {
      data() {
        return {
          tableData: [{
          
            name: '推送异常',
            address: 'https://kyd.oss.livingtrip.uyl.cn/27d3975776efb628beb13307bbc359e3.mp4'
          }, {
           
            name: '模糊订单推送',
            address: 'http://kyd.oss.livingtrip.uyl.cn/d5d2f4263c75b863418d4fe4a461fbb9.mp4'
          }, {
           
            name: '有订单异订单未点链接推送',
            address: 'http://kyd.oss.livingtrip.uyl.cn/a928322c5c692b351e6036778f5ef2ac.mp4'
          }, {
           
            name: '空驶补偿',
            address: 'http://kyd.oss.livingtrip.uyl.cn/e161c39e492726d1ac01209c18c1de44.mp4'
          }, {
           
            name: '预约单提示',
            address: 'http://kyd.oss.livingtrip.uyl.cn/cf1caf4a6bf1fbc84f338870df824043.mp4'
          }, , {
           
            name: '订单异常',
            address: 'http://kyd.oss.livingtrip.uyl.cn/a289076dc266283970315a63b9567655.mp4'
          }, ]
        }
      },
      methods: {
        handleEdit(index, row) {
          console.log(index, row);
          this.$refs.MusicPlay.src = row.address;
          this.$refs.MusicPlay.play();
          
          
        },
 
      }
    }
	
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}
</style>
